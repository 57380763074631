@import '../../../../scss/theme-bootstrap';

.signup-tout-wrapper {
  display: block;
  position: relative;
  margin-bottom: 10px;
  .signup-tout-background-image {
    min-height: 300px;
    img {
      min-height: 795px;
      @include breakpoint($landscape-up) {
        min-height: 0;
      }
      width: 100%;
    }
  }
  .signup-tout-content {
    position: absolute;
    left: 50%;
    top: 50%;
    display: inline-block;
    margin: auto;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    overflow: auto;
    width: 100%;
    max-width: 90%;
    @include breakpoint($landscape-up) {
      width: 100%;
      max-width: $max-width;
    }
    .email-signup__error {
      color: $color-error;
      margin-top: 10px;
    }
    input:-webkit-autofill {
      background: none;
    }
    .eyebrow {
      font-family: $font--neue-haas-unica-pro;
      font-size: 13px;
      display: block;
      letter-spacing: 0;
      color: $color-black;
      text-align: inherit;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
    .headline {
      font-family: $font--lamer-headline;
      font-size: 36px;
      line-height: 1;
      display: block;
      color: $color-dark-gray;
      letter-spacing: 0;
      text-align: inherit;
      font-weight: normal;
      margin-#{$ldirection}: 0;
      @include breakpoint($medium-up) {
        min-width: 660px;
      }
    }
    .subtitle {
      font-family: $font--neue-haas-unica-pro;
      font-size: 15px;
      line-height: 22px;
      display: block;
      letter-spacing: 0;
      color: $color-black;
      text-align: inherit;
      margin-top: 5px;
      margin-bottom: 5px;
      font-weight: normal;
      margin-#{$ldirection}: 0;
    }
    #email-signup__form,
    #sms-signup__form {
      overflow: hidden;
      margin: 35px auto 10px;
      width: 100%;
      max-width: 500px;
      input.form-text {
        font-size: 18px;
        display: block;
        float: left;
        margin-bottom: 1em;
        padding-bottom: 0;
        background: none;
        width: 80%;
        border: none;
        padding: 0;
        margin: 0;
        color: $color-black;
        &::-webkit-input-placeholder {
          color: $color-black;
          font-size: 20px;
        }
        &:-moz-placeholder {
          color: $color-black;
          font-size: 20px;
        }
        &::-moz-placeholder {
          color: $color-black;
          font-size: 20px;
        }
        &:-ms-input-placeholder {
          color: $color-black;
          font-size: 20px;
        }
      }
      input.form-submit {
        display: none;
      }
      .form-submit.email_signup--submit,
      .form-submit.sms_signup--submit {
        display: block;
        width: 20px;
        height: 30px;
        top: 10px;
        position: relative;
        float: right;
        min-width: 0;
        background: none;
        border: 0;
        cursor: pointer;
        @include icon('arrow--right', after);
        &:after {
          @include font-smoothing(false);
          color: $color-black;
          opacity: 1;
          font-weight: bold;
        }
      }
    }
    .email-signup {
      border-bottom: 1px solid $color-black;
      overflow: hidden;
      padding-bottom: 17px;
    }
    .email-promo {
      display: inline-block;
      margin: 10px 0;
    }
    .sms-signup__form--legal-copy {
      max-width: 660px;
    }
    &__panel {
      &--left {
        float: left;
        width: 18%;
        font-family: $font--lamer-headline;
        &-wrapper {
          position: absolute;
          top: 30%;
          transform: translateY(-50%);
          width: 150px;
          @include breakpoint($landscape-up) {
            width: 383px;
            top: 50%;
          }
        }
      }
      &--right {
        float: left;
        text-align: left;
        p {
          margin-bottom: 0;
        }
        @include breakpoint($landscape-up) {
          margin-left: 383px;
        }
        #email-signup__form,
        #sms-signup__form {
          margin-left: 0;
        }
      }
    }
    .thank-you-text {
      font-family: $font--lamer-headline;
      font-size: 15px;
      display: block;
      margin-top: 30px;
      margin-bottom: 40px;
      color: $color-dark-gray;
    }
  }
}

.signup-tout-wrapper .signup-tout__details .active-text {
  display: none;
}

.signup-tout-wrapper .signup-tout__details .signup-tout__details-copy {
  display: none;
}

.signup-tout-wrapper.details-active .active-text {
  display: inline-block;
}

.signup-tout-wrapper.details-active .inactive-text {
  display: none;
}

.signup-tout-wrapper .signup-tout__details a.signup-tout__details-trigger {
  text-transform: uppercase;
  border-bottom: 1px solid #1b1b1b;
  padding-bottom: 0.1em;
  text-decoration: none;
  letter-spacing: 0.075em;
  font-size: 11px;
}

.signup-tout-wrapper .signup-tout__details .signup-tout__details-reveal {
  font-size: 11px;
  margin-top: 30px;
  text-align: left;
}

.signup-tout-wrapper .signup-tout__details a.signup-tout__details-trigger:focus,
.signup-tout-wrapper .signup-tout__details a.signup-tout__details-trigger:hover {
  color: #005c3e;
}

@media (min-width: 768px) {
  .signup-tout-wrapper .signup-tout__details .signup-tout__details-reveal {
    font-size: 13px;
  }
}
